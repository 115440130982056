

function _newCookiePolicy(html) {

    let cookie_name = 'cookiepolicyinfo_new';

    $(".page").append(html.html);

    let obj = this;

    let HTML_box = $(".js-cp-box").hide();
    let HTML_general_box = $(HTML_box).find(".js-cp-general-box");
    let HTML_settings_box = $(HTML_box).find(".js-cp-settings-box");
    let HTML_settings_button = $(HTML_box).find(".js-cp-settings-button");
    let HTML_cancel_all_button = $(HTML_box).find(".js-cp-cancel-all-button");
    let HTML_accept_all_button = $(HTML_box).find(".js-cp-accept-all-button");
    let HTML_accept_selected_button = $(HTML_box).find(".js-cp-accept-selected-button");
    let HTML_show_cookie_settings = $(".js-cp-show-cookie-setting");
    let HTML_settings_cookies = $(HTML_box).find(".new-cp-content-settings-options-one-cookie");

    this.init = function () {

        if(!getCookie(cookie_name)) {
            $(HTML_box).show();
        }

        $(HTML_settings_box).hide();

        $(HTML_settings_button).click(function () {
            $(HTML_settings_box).slideDown(300);
            $(HTML_general_box).slideUp(300);
        });

        $(HTML_cancel_all_button).click(function(){
            setCookie(cookie_name,true,365);
            $(HTML_box).slideUp(300,function(){
                $(HTML_box).remove();
            });
            window.location.reload(true);
        });

        $(HTML_accept_all_button).click(function(){
            setCookie(cookie_name,true,365);
            $(HTML_settings_cookies).each(function(){
                if(!$(this).hasClass('disabled')) {
                    let special_cookie_name = $(this).attr('data-cookie');
                    setCookie(special_cookie_name,true,365);
                }
            });
            $(HTML_box).slideUp(300,function(){
                $(HTML_box).remove();
            });
            window.location.reload(true);
        });

        $(HTML_accept_selected_button).click(function(){
            setCookie(cookie_name,true,365);
            $(HTML_settings_cookies).each(function() {
                if (!$(this).hasClass('disabled')) {
                    if($(this).hasClass('on')) {
                        let special_cookie_name = $(this).attr('data-cookie');
                        setCookie(special_cookie_name, true, 365);
                    }
                    else {
                        let special_cookie_name = $(this).attr('data-cookie');
                        setCookie(special_cookie_name, true, -1);
                    }
                }
            });
            $(HTML_box).slideUp(300,function(){
                $(HTML_box).remove();
            });
            window.location.reload(true);
        });

        $(HTML_settings_cookies).on('click keypress',function(e){
            if(e.which === 13 || e.type === 'click') {
                if (!$(this).hasClass('disabled')) {
                    if ($(this).hasClass("on")) {
                        $(this).removeClass("on");
                        $(this).find(".new-cp-content-settings-options-one-cookie-checkbox").attr('aria-label',false);
                    } else {
                        $(this).addClass("on");
                        $(this).find(".new-cp-content-settings-options-one-cookie-checkbox").attr('aria-label',true);
                    }
                }
            }
        });

        if(HTML_show_cookie_settings.length>0) {
            $(HTML_show_cookie_settings).click(function (e) {
                $(HTML_box).show();
                $(".new-cp-content-settings-options-one-cookie").each(function () {
                    if (!$(this).hasClass('disabled')) {
                        let special_cookie_name = $(this).attr('data-cookie');
                        if (getCookie(special_cookie_name)) {
                            $('div[data-cookie=' + special_cookie_name + ']').addClass('on');
                        }
                    }
                });
            });
        }
    }

    obj.init();

}
